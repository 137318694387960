import { createTheme } from '@mui/material/styles'
import palette from './palette';
import typography from './typography';
import components from './components';

const baseTheme = {
  palette,
  typography,
 components
};

export const theme = createTheme ({ ...baseTheme, direction: 'ltr' });

export const themeWithRtl = createTheme ({ ...baseTheme, direction: 'rtl' });
