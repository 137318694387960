export const defaultLocale = 'ar' as const;

export const locales = ['en', 'ar'] as const;
export const rtlLocales = ['ar'] as const;

// need to customize later
export const languageNames = {
  en: 'English',
  ar: 'arabic',
 
};
