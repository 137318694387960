export default {
  styleOverrides: {
    root: {
      borderRadius: "16px",
      paddingLeft: "5px",
      paddingRight: "5px",
      notchedOutline: {
   
      borderColor:"unset",
      boxShadow:" 0 0 10px #bcbcbc;" ,
      },
     
    
    },
    notchedOutline: {
  
      borderColor:"unset",

      border:"none"

      },
 
  }
};
