export default {
  styleOverrides: {  root: {
    // alignSelf: "flex-start",
    // left:0,
    // right:0,
  },
  elevation1: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
  }
}
};
