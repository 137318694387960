export default {
    styleOverrides: {  root: {
        textAlign:"center"
    },
    yearButton:{
        textAlign:"center"  
    }
   
  }
  };
  