import palette from '../palette';

export default {
  styleOverrides: {  root: {
    // backgroundColor:"transparent !important",
    color: palette.icon,
    // '&:hover': {
    //   backgroundColor: 'rgba(0, 0, 0, 0.03)'
    // }
  }
}
};
