import * as config from "../../../../next.config.js";
import axios from 'axios';
import { constants } from '../../../../constants';
import {registrationRedis} from '../../cache/entityDetailsObject'
async function getlanguageFunction(searchData,req) {
    let i = 0;
    let queryObject = {}
    let limit = searchData.limit ? searchData.limit : 10
    let pageId = searchData.pageId ? searchData.pageId : 1
    queryObject["fields"] = {
        "fields": [
            "language.id",//0
            "language.name",//1
            "language.code",//2
            "language.updatedOn",//3
            "language.statusId",//4
            "language.rightToLeft",//5
            "language.defaultLang",//6
        ]
    }
    queryObject.pageable = {
        "pageId": pageId,
        "limit": limit,
        "sort": {
            "1": {
                "fieldName": "language.updatedOn",
                "sortType": "desc"
            }
        }
    };
    if(searchData.hasOwnProperty("statusId")){
        queryObject[i]={
            "fieldName":"statusId",
            "value":searchData.statusId,
            "valueOperator":"IN"
        }
    }
    const request = await axios({
        url: `${config['env']['apiBaseUrl']}/api/global/v1/lang`,
        method: "GET",
        data: queryObject,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const content = request.data.content;
    let contentArray = [];
    for (let cnt of content) {
        contentArray.push({
            "id": cnt[0],
            "name": cnt[1],
            "code": cnt[2],
            "updatedOn": cnt[3],
            "statusId": cnt[4],
            "rightToLeft": cnt[5],
            "defaultLang":cnt[6]
        });
    }
    return {data:contentArray,pageable:request.data.pageable}
}
export  {getlanguageFunction}


async function languageFunction(data,req) {
    let getAddressDetailsData= await registrationRedis.get(constants.globalLanguage)
    if (getAddressDetailsData!=null) {
           let responseData = JSON.parse(getAddressDetailsData);
           return responseData.data
       } else {
       let responseData= await getlanguageFunction({...data,pageId:1},req);
       if (responseData.data.length>0) {
            await registrationRedis.set(constants.globalLanguage,JSON.stringify(responseData),'PX',constants.globalLanguageExpireTime)   
       }
       return responseData.data;
       }
   }

   export default languageFunction