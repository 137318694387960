// import { colors } from '@mui/material';

export default {
  styleOverrides: { 
    root:{
      justifyContent:"flex-start",
    },
  contained: {
    backgroundColor: "red",
    '&:hover': {
      backgroundColor: "red"
    },

  }
}
};
