import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheetManager } from 'styled-components';
import rtlPlugin from 'stylis-plugin-rtl';


function CustomStylesProvider({ direction, children }) {
  if (direction !== 'rtl') {
    return children
  }

  return (
    <StyleSheetManager stylisPlugins={[rtlPlugin]}>
      {children}
    </StyleSheetManager>
  );
}

CustomStylesProvider.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.string
};

export default CustomStylesProvider;
