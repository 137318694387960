
const addItemToCart = (state, action) => {
// console.log("items=========>",state)

  const existingCartItemIndex = state.items&&state.items.findIndex(
    (item) => item.entityId == action.payload.entityId &&item.productUnitPrice==action.payload.productUnitPrice&&item.entityDetailsId==action.payload.entityDetailsId
  );
  let newState = [...state.items];

  if (existingCartItemIndex > -1) {


return state.items.reduce((acc, item) => {
 
    if (item.entityId === action.payload.entityId &&item.productUnitPrice==action.payload.productUnitPrice&&item.entityDetailsId==action.payload.entityDetailsId) {

      const newQuantity = item.quantity +action.payload.quantity;

      if(action.payload.isFavoriate==true){
        return  [...acc, { ...item, isFavoriate: true }];
        
        
      }       
      if(action.payload.quantity >0){
      return newQuantity > 0
        ? [...acc, { ...item, quantity: newQuantity }]
        : [...acc];


    }
  }


    return [...acc, item];
  }, []);
  }
  return [...newState, action.payload];
};

// cartItems, cartItemToRemove
const removeItemFromCart = (state, action) => {

  return state.items.reduce((acc, item) => {

    if (item.entityId === action.payload.entityId &&item.productUnitPrice==action.payload.productUnitPrice&&item.entityDetailsId==action.payload.entityDetailsId) {
      const newQuantity = item.quantity - action.payload.quantity;
  
      if(action.payload.isFavoriate==false && action.payload.quantity == 0){
       

        return  [...acc];
        
      }
      
      if(action.payload.quantity >0  && item.isFavoriate==true ){
    
   
        return  [...acc, { ...item, quantity: newQuantity,isFavoriate:true }]
    
      }
      if(action.payload.quantity >0  && item.isFavoriate==false ){
    
      return newQuantity > 0
        ? [...acc, { ...item, quantity: newQuantity,isFavoriate:false }]
        : [...acc];
      }
    }
    
    return [...acc, item];
  }, []);
};

const clearItemFromCart = (state, action) => {

  return state.items.reduce((acc, item) => {

    if (item.productUnitPrice === action.payload.productUnitPrice &&item.isFavoriate==true) {
          
      return [...acc, { ...item, quantity: 0 }]

       
    }

    // if(item.entityId === action.payload.entityId &&item.isFavoriate==false && item.quantity>0){
    //   return [...acc]


    // }
    if(item.productUnitPrice === action.payload.productUnitPrice &&item.isFavoriate==false){
      return [...acc]


    }

    return [...acc, item];
  }, []);
  


};
const addItemForFavoriteToCart = (state, action) => {

  let newState = [...state.items];

    return [...newState, action.payload];
  
};
  
const clearItemFavoriteFromCart = (state, action) => {

  return state.items.filter((item) => item.entityId !== action.payload.entityId);
};
const ChangeQuantityItemToCart = (state, action) => {

  const existingCartItemIndex = state.items.findIndex(
    (item) => item.entityId == action.payload.entityId &&item.productUnitPrice==action.payload.productUnitPrice&&item.entityDetailsId==action.payload.entityDetailsId
  );
  let newState = [...state.items];
  if (existingCartItemIndex > -1) {
return state.items.reduce((acc, item) => {
    if (item.entityId === action.payload.entityId &&item.productUnitPrice==action.payload.productUnitPrice&&item.entityDetailsId==action.payload.entityDetailsId) {

      const newQuantity =   action.payload.quantity;

      if(action.payload.isFavoriate==true){
        return  [...acc, { ...item, isFavoriate: true }];
        
        
      }       
      if(action.payload.quantity != null ){
      return newQuantity > 0
        ? [...acc, { ...item, quantity: newQuantity }]
        : [...acc];


    }
  
  }


    return [...acc, item];
  }, []);
  }
  return [...newState, action.payload];
};
const clearCart = (state) => {

  return state.items.reduce((acc,item ) => {

    if (item.productUnitPrice != null &&item.isFavoriate==true) {
  
     return [...acc, { ...item, quantity: 0 }]

    }    
    if(item.productUnitPrice != null &&item.isFavoriate==false){
      return [...acc]

    }
    return [...acc, item];
  }, []);
};
const addFeatureItemToCart = (state, action) => {

 if(state.items && state.items.length>0){
return state.items.reduce((acc, item) => {
    if (action.payload.productUnitPriceFeatures.productUnitPriceId ==item.productUnitPrice) {
    if(item.productUnitPriceFeatures && item.productUnitPriceFeatures.length>0 && item.productUnitPriceFeatures.filter(a=>a.productUnitPriceFeaturesDetails.ProductUnitPriceId==action.payload.productUnitPriceFeatures.productUnitPriceFeaturesDetails.ProductUnitPriceId && a.productUnitPriceFeaturesDetails.id==action.payload.productUnitPriceFeatures.productUnitPriceFeaturesDetails.id).length>0){
    let tempProductUnitPriceFeatures = item.productUnitPriceFeatures.map(x=>{
      if(action.payload.productUnitPriceFeatures.productUnitPriceFeaturesDetails.ProductUnitPriceId ==x.productUnitPriceFeaturesDetails.ProductUnitPriceId && action.payload.productUnitPriceFeatures.productUnitPriceFeaturesDetails.id ==x.productUnitPriceFeaturesDetails.id){
         let newQuantity=x.quantity+action.payload.productUnitPriceFeatures.quantity
         x.quantity=newQuantity
      }
      return x
    })
  return    [...acc, { ...item, productUnitPriceFeatures:  tempProductUnitPriceFeatures }]
  }else{
   let tempProductUnitPriceFeatures=[]
    if(item.productUnitPriceFeatures && item.productUnitPriceFeatures.length>0 ){
     tempProductUnitPriceFeatures= [...item.productUnitPriceFeatures ,...[action.payload.productUnitPriceFeatures]]
    }else{
      tempProductUnitPriceFeatures=[action.payload.productUnitPriceFeatures]
    }
        return   [...acc, { ...item, productUnitPriceFeatures: action.payload.productUnitPriceFeatures.productUnitPriceId == item.productUnitPrice?tempProductUnitPriceFeatures:[] }]
    }
    
  
  }
  return [...acc, item];
  }, []);
}

};
const removeFeatureItemToCart = (state, action) => {
  // console.log("action================>",action)
  if(state.items && state.items.length>0){
  return state.items.reduce((acc, item) => {
      if (action.payload.productUnitPriceFeatures.productUnitPriceId ==item.productUnitPrice) {
      if(item.productUnitPriceFeatures && item.productUnitPriceFeatures.length>0 && item.productUnitPriceFeatures.filter(a=>a.productUnitPriceFeaturesDetails.ProductUnitPriceId==action.payload.productUnitPriceFeatures.productUnitPriceFeaturesDetails.ProductUnitPriceId && a.productUnitPriceFeaturesDetails.id==action.payload.productUnitPriceFeatures.productUnitPriceFeaturesDetails.id).length>0){
      let tempProductUnitPriceFeatures = item.productUnitPriceFeatures.map(x=>{
        if(action.payload.productUnitPriceFeatures.productUnitPriceFeaturesDetails.ProductUnitPriceId ==x.productUnitPriceFeaturesDetails.ProductUnitPriceId && action.payload.productUnitPriceFeatures.productUnitPriceFeaturesDetails.id ==x.productUnitPriceFeaturesDetails.id){
         if(action.payload.productUnitPriceFeatures.quantity>0){
          let newQuantity=x.quantity-action.payload.productUnitPriceFeatures.quantity
           x.quantity=newQuantity
         }else{
          // console.log("m==========>",)
          x.quantity=0
         }

        }
        return x
      })
// console.log("tempProductUnitPriceFeature========>",tempProductUnitPriceFeatures.filter(i=>i.quantity>0))
    return    [...acc, { ...item, productUnitPriceFeatures:  tempProductUnitPriceFeatures.filter(i=>i.quantity>0).length>0? tempProductUnitPriceFeatures.filter(i=>i.quantity>0):[] }]
    }
      
  }
  return [...acc, item];
    }, []);
    
  }
  };
export const reducer = (state, action) => {
  switch (action.type) {
    case 'REHYDRATE':
      return { ...state, ...action.payload };
    case 'TOGGLE_CART':
      return { ...state, isOpen: !state.isOpen };
    case 'ADD_ITEM':
      return { ...state, items: addItemToCart(state, action) };
    case 'REMOVE_ITEM':
      return { ...state, items: removeItemFromCart(state, action) };
    case 'CLEAR_ITEM_FROM_CART':
      return { ...state, items: clearItemFromCart(state, action) };
      case 'CLEAR_CART':
        return { ...state, items:  clearCart(state) };
    case 'ADD_ITEM_Favorite':
        return { ...state, items: addItemForFavoriteToCart(state, action) };
    case 'CLEAR_ITEM_Favorite_FROM_CART':
        return { ...state, items: clearItemFavoriteFromCart(state, action) };
    case 'Select_Quantity_ITEM':
      return { ...state, items: ChangeQuantityItemToCart(state, action) };
      case 'ADD_FEAUTURE_ITEM':
        return { ...state, items: addFeatureItemToCart(state, action) };
        case 'REMOVE_FEAUTURE_ITEM':
          return { ...state, items: removeFeatureItemToCart(state, action) };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
