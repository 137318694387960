module.exports={
    i18n:{
        locales: ['en', 'ar'],
        defaultLocale: 'ar',
        localeDetection: false,
        
    },
    // domains: [
    //     // {
    //     //   // Note: subdomains must be included in the domain value to be matched
    //     //   // e.g. www.example.com should be used if that is the expected hostname
    //     //   domain: 'localhost:3000/en',
    //     //   defaultLocale: 'en',
    //     // },
    //     {
    //         domain: 'localhost:3000/ar',
    //         defaultLocale: 'ar',
    //     },
    
    //   ],
}