import React, { useReducer, useContext, createContext } from 'react';
import { reducer } from './cart.reducer';
import { useStorage } from '../../utils/use-storage';
// import {getCookieValue} from '../../components/helpers/helpers'
const CartContext = createContext({} as any);
const INITIAL_STATE = {
  isOpen: false,
  items: [],
  isRestaurant: false,
  coupon: null,
};
const  types=["products","medical","diet"]
const useCartActions = (initialCart = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialCart);

  const addItemHandler = (item) => {
  
    dispatch({ type: 'ADD_ITEM', payload: {...item} });

  };
  
  const addItemForFavoriteHandler = (item,quantity=0) => {
  
    dispatch({ type: 'ADD_ITEM_Favorite', payload: {...item,quantity} });

  };

  const removeItemHandler = (item) => {

    dispatch({ type: 'REMOVE_ITEM', payload: { ...item } });
  };

  const clearItemFromCartHandler = (item) => {
    dispatch({ type: 'CLEAR_ITEM_FROM_CART', payload: item });
  };

  const clearItemFavoriteFromCartHandler = (item) => {
    dispatch({ type: 'CLEAR_ITEM_Favorite_FROM_CART', payload: item });
  };
  const clearCartHandler = () => {
    dispatch({ type: 'CLEAR_CART' });
  };
  const toggleCartHandler = () => {
    dispatch({ type: 'TOGGLE_CART' });
  };

  const rehydrateLocalState = (payload) => {
    dispatch({ type: 'REHYDRATE', payload });
  };
  const isInCartHandler = (entityId,type,productUnitPrice,entityDetailsId ,userCountry) => {

    return state.items?state.items.some((item) =>item.entityDetailsId==entityDetailsId&&productUnitPrice==item.productUnitPrice&& item.entityId === entityId&&   types.includes(item.type)&& item.userCountry===userCountry && item.quantity>0):null;
  };

  const getItemHandler = (entityId,productUnitPrice,entityDetailsId,userCountry) => {
    return state.items?state.items.find((item) => item.entityId === entityId&&item.entityDetailsId==entityDetailsId&&productUnitPrice==item.productUnitPrice&& userCountry==item.userCountry):null;
  };

  const getItemFavoriteHandler = (entityId) => {

    return state.items?state.items.find((item) => item.entityId === entityId):null;
  };
  const getItemsForEntityHandler = (entityDetailsId ,userCountry) => {
 
    return  state &&state.items?state.items.filter((item) =>item.entityDetailsId==entityDetailsId&&item.productUnitPrice!= null&& types.includes(item.type)&& item.userCountry===userCountry && item.quantity > 0):null;
  };
  // const getCartItemsPrice = () => cartItemsTotalPrice(state.items).toFixed(2);
  // const getCartItemsTotalPrice = () =>
  //   cartItemsTotalPrice(state.items, state.coupon).toFixed(2);
  // const getDiscount = () => {
  //   const total = cartItemsTotalPrice(state.items);
  //   const discount = state.coupon
  //     ? (total * Number(state.coupon?state.coupon.discountInPercent:null)) / 100
  //     : 0;
  //   return discount.toFixed(2);
  // };

  // const getItemsCount = state.items?state.items.reduce(
  //   (acc, item) => 

  // item.quantity,
  //   0
  // ):null;
 
  const editUnits=(id,res)=>{
    let getObj= state.items?state.items.find((item) => item.id === id):null
        getObj.id=id
        getObj.price=res[0].price
        getObj.unitId=res[0].unitId
        getObj.unit=res[0].unit
        return getObj
  }
  const selectQuantityHandler = (item) => {
  
    dispatch({ type: 'Select_Quantity_ITEM', payload: {...item} });

  };
  const addFeatureItemHandler = (item) => {
  
    dispatch({ type: 'ADD_FEAUTURE_ITEM', payload: {...item} });

  };
  const getFeatureItemHandler = (entityId,productUnitPrice,entityDetailsId,userCountry,productUnitPriceFeaturesDetailsId,productUnitPriceFeaturesDetailsProductUnitPriceId) => {
if(  state.items){
 let item= state.items.find((item) => item.entityId === entityId&&item.entityDetailsId==entityDetailsId&&productUnitPrice==item.productUnitPrice&& userCountry==item.userCountry)
return  item &&item.productUnitPriceFeatures?item.productUnitPriceFeatures &&item.productUnitPriceFeatures.length>0&& item.productUnitPriceFeatures.filter(i=>i.productUnitPriceFeaturesDetails.id==productUnitPriceFeaturesDetailsId && i.productUnitPriceFeaturesDetails.ProductUnitPriceId==productUnitPriceFeaturesDetailsProductUnitPriceId).length>0?item.productUnitPriceFeatures &&item.productUnitPriceFeatures.length>0&& item.productUnitPriceFeatures.filter(i=>i.productUnitPriceFeaturesDetails.id==productUnitPriceFeaturesDetailsId && i.productUnitPriceFeaturesDetails.ProductUnitPriceId==productUnitPriceFeaturesDetailsProductUnitPriceId)[0]:null:null
}
  };
  const removeFeatureItemHandler = (item) => {
  
    dispatch({ type: 'REMOVE_FEAUTURE_ITEM', payload: {...item} });

  };
  return {
    state,
    // getItemsCount,
    rehydrateLocalState,
    addItemHandler,
    removeItemHandler,
    clearItemFromCartHandler,
    clearCartHandler,
    isInCartHandler,
    getItemHandler,
    toggleCartHandler,
    // getCartItemsTotalPrice,
    // getCartItemsPrice,
    // getDiscount,
    editUnits,
    addItemForFavoriteHandler,
    clearItemFavoriteFromCartHandler,
    getItemFavoriteHandler,
    getItemsForEntityHandler,
    selectQuantityHandler,
    addFeatureItemHandler,
    getFeatureItemHandler,
    removeFeatureItemHandler
  };
};

export const CartProvider = ({ children }) => {
  const {
    state,
    rehydrateLocalState,
    // getItemsCount,
    addItemHandler,
    removeItemHandler,
    clearItemFromCartHandler,
    clearCartHandler,
    isInCartHandler,
    getItemHandler,
    toggleCartHandler,
    // getCartItemsTotalPrice,
    // getCartItemsPrice,
    // getDiscount,
    editUnits,
    addItemForFavoriteHandler,
    clearItemFavoriteFromCartHandler,
    getItemFavoriteHandler,
    getItemsForEntityHandler,
    selectQuantityHandler,
    addFeatureItemHandler,
    getFeatureItemHandler,
    removeFeatureItemHandler
  } = useCartActions();
  const { rehydrated, error } = useStorage(state,rehydrateLocalState);

  return (
    <CartContext.Provider
      value={{
        isOpen: state.isOpen,
        items: state.items,
        coupon: state.coupon,
        isRestaurant: state.isRestaurant,
        cartItemsCount: state.items?state.items.length:[],
        // itemsCount: getItemsCount,
        addItem: addItemHandler,
        removeItem: removeItemHandler,
        removeItemFromCart: clearItemFromCartHandler,
        clearCart: clearCartHandler,
        isInCart: isInCartHandler,
        getItem: getItemHandler,
        toggleCart: toggleCartHandler,
        // calculatePrice: getCartItemsTotalPrice,
        // calculateSubTotalPrice: getCartItemsPrice,
        // calculateDiscount: getDiscount,
        editUnits:editUnits,
        addItemFavorite: addItemForFavoriteHandler,
        removeItemFavorite: clearItemFavoriteFromCartHandler,
        getItemFavorite: getItemFavoriteHandler,
        getItemsForEntity: getItemsForEntityHandler,
       selectQuantityItem:selectQuantityHandler,
       addFeatureItem :addFeatureItemHandler,
       getFeatureItem:getFeatureItemHandler,
       removeFeatureItem: removeFeatureItemHandler,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
