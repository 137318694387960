import React, {useEffect  ,useState}from 'react'
import Router from 'next/router';
import { CircularProgress } from '@mui/material';

export default function Loader(){
    const [isloading,setloading]= useState(false);

    useEffect(() => {
        setloading(true)
        Router.events.on('routeChangeStart',(url)=>{
            setloading(true)
        })  ;
        Router.events.on('routeChangeComplete',(url)=>{
             setloading(false)
        })  ;
        Router.events.on('routeChangeError',(url)=>{
            setloading(false)
       })  ;
       Router.events.on('hashChangeStart', (url) => {
        setloading(true)
      });
  
      Router.events.on('hashChangeComplete', (url) => {
        setloading(false)
      });
      Router.events.on('beforeHistoryChange', (url) => {
        setloading(true)
      });

        return ()=>{
            Router.events.off('routeChangeStart',(url)=>{
    
                setloading(true)
              
             
            })  ;
            Router.events.off('routeChangeComplete',(url)=>{
                
              
                 setloading(false)
                
            })  ;
         
        }
      
     
    }, [Router])
  
if(!isloading) return null;

return(
    <>
  

       {<div style={{
           position:"fixed",
           top:0,
           bottom:0,
           left:0,
           right:0,
           display:"flex",
           alignContent:"center",
           alignItems:"center",
           background:"#ffffffbf",
           textAlign: "center",
            justifyContent:"center",
           zIndex:40,

       }}>
        {/* loading */}
        {/* <Loading start={isloading}/> */}
        {/* <img src={`${config["basePath"]}/json/loading_red.gif`}/> */}
        {/* <div    ref={lottieDiv}></div> */}
        <CircularProgress/>
    </div>}

 </>
);
}
