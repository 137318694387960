import { QueryClient } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

import { compress, decompress } from 'lz-string';

export const queryClient = new QueryClient({ defaultOptions: { queries: {   staleTime: 60 * 1000,refetchOnWindowFocus: false, } } });

persistQueryClient({
  queryClient: queryClient,
  persister: createSyncStoragePersister({
    storage: typeof window !== "undefined"&&window.localStorage,
    serialize: data => compress(JSON.stringify(data)),
    deserialize: data => JSON.parse(decompress(data)),
  }),
  maxAge: Infinity,
});