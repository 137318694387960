import { blueGrey } from '@mui/material/colors';

export default {
  styleOverrides: { root: {
    borderRadius: 3,
    overflow: 'hidden'
  },
  colorPrimary: {
    backgroundColor: blueGrey[50]
  }
}
};
