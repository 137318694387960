// "use strict";
const Redis=require('ioredis')
let registrationRedis=null
//online local 136 db 1 192.168.1.136
//online 127.0.0.1 db 1
// 138 192.168.1.138 db 1
//for development local
// 136 ===== 192.168.1.138 ====db3
// 138 ===== 192.168.1.138 ====db2
// online ===== 192.168.1.138 ====db4
// if(registrationRedis==null){
 registrationRedis=new Redis({port:6379,host:'127.0.0.1',db:1,lazyConnect:false,connectTimeOut:10000})
// }
 registrationRedis.select(1,(err, result) => {
         console.log("redis connect========>",err, result);
});
export {registrationRedis}