import { colors } from "@mui/material";

export default {
  styleOverrides: {
    root: {
    //   backgroundColor: colors.blueGrey[50],
    //   color: colors.blueGrey[900]
    textAlign:"revert"
    },
  
  }
};
