export default {
  styleOverrides: { root:{
textAlign:"right",
},
  button: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
}
};
