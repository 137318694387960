import axios from "axios";
import * as config from "../../../../next.config.js";
import languageFunction from '../languages/languageFunction';
import {getCookieAuthorization} from '../../helpers/HeaderRequest'

// const cacheObject = require("memory-cache");

let allData=[]
let limit=1000
let pageId =1
async function getTermsFunction(searchData, req) {
  let queryObject = {};
  let i = 0;
  // let  request;
  // let getData=[]
  const language = await languageFunction({},req);
  let headersObj = getCookieAuthorization(req);
  pageId = searchData.hasOwnProperty("pageId") ? searchData.pageId : pageId;

i++



   if(searchData.hasOwnProperty("vocabCode")){  
   queryObject[i] = {
    "fieldName": "code",
    "value": searchData.vocabCode,
    "join": [
        "vocabulary"
    ],
   
    "valueOperator": "IN"
    }
    i++
  }
//  if(searchData.hasOwnProperty("field2")){
//     queryObject[i] = {
//         "fieldName": "field2",
//         "value": searchData.field2,
//     }
//     i++
//  }
//  if(searchData.hasOwnProperty("field3")){
//   queryObject[i] = {
//       "fieldName": "field3",
//       "value": searchData.field3,
//       "valueOperator": "CONTAINS"
//   }
//   i++
// }

//  if(searchData.hasOwnProperty("field1")){
//     queryObject[i] = {
//         "fieldName": "field1",
//         "value": searchData.field1,
//         "valueOperator": "IN"
//     }
    
//     i++
//  }
//  if(searchData.hasOwnProperty("field1InhakeemService")){
//   queryObject[i] = {
//       "fieldName": "field1",
//       "value": searchData.field1InhakeemService,
//       "valueOperator": "CONTAINS"
//   }
  
//   i++
// }

//  if(searchData.hasOwnProperty("termCode")){
//     queryObject[i] = {
//         "fieldName": "code",
//         "value": searchData.termCode,
//     }
//     i++
//  }


//  if(searchData.hasOwnProperty("termId")){
//   queryObject[i] = {
//       "fieldName": "id",
//       "value": searchData.termId,
//       "valueOperator": "IN"
//   }
//   i++
// }
// if(searchData.hasOwnProperty("statusId")){

//   queryObject[i] = {
//       "fieldName": "statusId",
//       "value": [1],
//       "valueOperator": "IN"
//   }
//   i++
// }

 
  queryObject["fields"] = {
    fields: [
        "term.id", //0
        "term.code", //1
        "term.statusId", // 2
        "term.field1", // 3
        "term.field2", // 4
        "term.field3", // 5
        "vocabulary.id", // 6
        "vocabulary.code", // 7
        "transTerm.name", // 8
        "transTerm.id", // 9
        "transTerm.language.id" ,// 10
         "term.field4", // 11
         "term.fieldConfig", //12
         "term.weight"//13
    ],
    "joins": [
        "vocabulary",
        "transTerm",
        "transTerm.language"
    ],
    
  };
  queryObject.code = {
    "code": "code-02"
}
queryObject.pageable = {
    "pageId": pageId,
    "limit": limit,
    "sort": {
        "1": {
            "fieldName": "term.weight",
            "sortType": "asc"
        }
    }
}
// console.log("d=============>",JSON.stringify(queryObject))
return await axios({
  method: "GET",
  url: `${config['env']['apiBaseUrl']}/api/global/v1/term`,
  headers: headersObj,
  data: queryObject
}).then(async res=>{
  for (let data of res.data.content) {
    allData.push({
        "id": data[0],
        "code": data[1],
        "statusId": data[2],
        "field1": data[3],
        "field2": data[4],
        "field3": data[5],
        "vocId": data[6],
        "vocCode": data[7],
        "name": data[8],
        "transTermId": data[9],
        "langId": data[10],
       "language":language.find(x=>x.id==data[10]),
       "field4": data[11],
       "fieldConfig": data[12],
       "weight":data[13]
    });


}

if(limit==res.data.pageable.numberOfElements){
  if(limit*pageId<res.data.pageable.totalElements){
    pageId=pageId+1

    
    await getTermsFunction({...searchData,pageId:pageId},req)

    
  }
}

allData=[...new Map([...allData].map(ele=>[ele.transTermId,ele])).values()]



return allData
}).catch(err=>{
  // console.log("err==========>",err)
})








}

export default getTermsFunction;
