import { red,blue,blueGrey,grey } from '@mui/material/colors';

const white = '#FFFFFF';

export default {
  primary: {
    contrastText: white,
    dark: '#F52A59',
    main:'#F52A59',
    light: '#F52A59'
  },
  secondary: {
    contrastText: white,
    dark: "#008C8D",
    main:"#008C8D",
    light:"#008C8D",
  },
  error: {
    contrastText: white,
    dark: red[900],
    main: red[600],
    light: red[400]
  },
  text: {
    primary: blueGrey[900],
    secondary: blueGrey[600],
    link: blue[600]
  },
  link: blue[800],
  icon: blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: grey[200],
  
};
