import Router from 'next/router'
export default {
    styleOverrides: {  root: {
        // direction:"rtl"

      // alignSelf: "flex-start"
    },
 
  }
}
  