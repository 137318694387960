import palette from '../palette';

export default {
  styleOverrides: { 
  root: {
      color:"#0c1444",
      backgroundColor:"#ffffff",
      paddingLeft:5,
      paddingRight:5,
     
      "&.Mui-focused":{
        color:"#0c1444",  
    },
    textAlign:"right"
    },
   

}
};
