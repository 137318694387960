
export default {
    styleOverrides: {
      root: {
        // "&.MuiOutlinedInput-notchedOutline": {
        //   borderColor: "#d73f93 !important"
        // },
        borderRadius: 30,
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#0c1444 "
        },
      },
    //   notchedOutline: {
    //     borderColor: "#d73f93 !important"
    //   },
    //   fieldset:{
    //       borderColor: "#d73f93 !important"
    //   }
    }
  };
  