// "use strict";

const cacheObject = require("memory-cache");
import {
    constants
} from '../../../../constants'
import getTermsFunction from './getTermsFunction'
import {registrationRedis} from '../../cache/entityDetailsObject'
async function getTermIdByVocabAndTermCode(termCode, vocabCode,req) {
        let getTermsRes=await getTermsFunction({vocabCode:vocabCode},req)
        const terms = getTermsRes;
        let id = terms ? terms.filter(i => i.code == termCode)[0].id : ""
        return id;
}
async function getTermsIdsByVocabAndTermsCodes(termCodes, vocabCode,req) {
   
    let arrOfIds = []
    if (cacheObject.keys().includes(vocabCode)) {
      
        const terms = JSON.parse(cacheObject.get(vocabCode));
    
       terms.forEach(element => {
            if (termCodes.includes(element.code)) {

                arrOfIds.push(element.id)
            }


        }) 
      
  
        return arrOfIds;
    } else {
     

        let getTermsRes=await getTermsFunction({vocabCode:[vocabCode]},req)
        if (getTermsRes) {
            cacheObject.put(vocabCode, JSON.stringify(getTermsRes), constants.memoryCacheExpire);
    
        }
        const terms = JSON.parse(cacheObject.get(vocabCode));
        terms.forEach(element => {
            if (termCodes.includes(element.code)) {

                arrOfIds.push(element.id)
            }


        });

        return arrOfIds;
    }
}

async function getvocabTermsObj(vocabCode,req) {
    let tempVaocabLst=[]
    let lstOfTerms=[]
   
    try{
for(let i=0;i< vocabCode.vocabCode.length;i++){
    try{
        let lst=await registrationRedis.get("global_web_vocab_"+vocabCode.vocabCode[i]+"_terms")
        if(!lst||lst.length==0){
            tempVaocabLst.push(vocabCode.vocabCode[i])
        }
        else{
            lstOfTerms.push(...JSON.parse(lst))
        }
    }
    catch(err){
     }
}
    if(tempVaocabLst.length>0){
        let getTermsRes= await getTermsFunction({...vocabCode,vocabCode:tempVaocabLst,pageId:1},req)  
      
        // console.log("tempVaocabLst===============>",tempVaocabLst[0]=="entity-image-types"?getTermsRes:"")
        for(let i=0;i<tempVaocabLst.length;i++){
            lstOfTerms.push(...getTermsRes.filter(r=>r.vocCode==tempVaocabLst[i]))
            try{
                if(getTermsRes.filter(r=>r.vocCode==tempVaocabLst[i]).length>0){
                    await registrationRedis.set("global_web_vocab_"+tempVaocabLst[i]+"_terms",JSON.stringify(getTermsRes.filter(r=>r.vocCode==tempVaocabLst[i])),'PX',constants.vocabTermsExpireTime) 
                }
            }
            catch(err){
                }
        }
     return lstOfTerms
    }
    return lstOfTerms;
}
catch(err){

}
}


// async function getvocabTermsObjFromCache(vocabCode,req) {
 
 
//     // try{
//     if (cacheObject.keys().includes("terms") && cacheObject.get("terms") != null) {
       
//         const terms = JSON.parse(cacheObject.get("terms"));

//         return terms
//     } else {

//     let getTermsRes=await getTermsFunction(vocabCode,req)
 

//         if (getTermsRes) {
//         cacheObject.put("terms", JSON.stringify(getTermsRes), constants.memoryCacheExpire);

//     }


//     return getTermsRes;
// }

// }
// async function getvocabTermsObjByVocabIdAndTid(vocabCode, termid,req) {
//     let queryObject = {};

//     if (cacheObject.keys().includes(vocabCode) && cacheObject.get(vocabCode) != null) {
//         const terms = JSON.parse(cacheObject.get(vocabCode));
       
//         let newterms = terms.filter(i => i.id == termid)
 
//         return newterms;
//     } else {
//         let getTermsRes=await getTermsFunction({vocabCode:vocabCode},req)
     
//             if (getTermsRes) {
//                 cacheObject.put(vocabCode, JSON.stringify(getTermsRes), constants.memoryCacheExpire);
//             }
//     const terms = JSON.parse(cacheObject.get(vocabCode));
//     let newterms = terms.filter(i => i.id == termid)

//     return newterms;
//     }
// }



async function getSubCategoriesByMainCat(vocabCode, code,req) {
    let queryObject = {};

    if (cacheObject.keys().includes(vocabCode) && cacheObject.get(vocabCode) != null) {


        const terms = JSON.parse(cacheObject.get(vocabCode));
        let newterms = terms.filter(i => i.field1 == code)

        return newterms;

    } else {

    

    // await getTerms(queryObject, vocabCode)
    let getTermsRes=await getTermsFunction(vocabCode,req)
    if (getTermsRes) {
    cacheObject.put(vocabCode, JSON.stringify(getTermsRes), constants.memoryCacheExpire);

}
    const terms = JSON.parse(cacheObject.get(vocabCode));
    let newterms = terms.filter(i => i.field1 == code)

    return newterms;
    }
}


export {
    getTermIdByVocabAndTermCode,
 
    getvocabTermsObj,
    getTermsIdsByVocabAndTermsCodes,
    // getvocabTermsObjByVocabIdAndTid,
    getSubCategoriesByMainCat,
    // getvocabTermsObjFromCache
}