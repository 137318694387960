import {encryption, decrypt, printLogs} from "../../../components/helpers/helpers"

function getRequestHeader(request) {

  let req = request.headers
  let array = []
  array.push(req)

  return array
}

function getCookieCountry(request) {

  let req = request.headers;
  let array = [];
  array.push(req);
  let dataTrim = array
    .map(i => (i["cookie"] ? i["cookie"].split(";") : []))[0]
    .map(i => i.trim());
  let x =
    dataTrim.length > 0
      ? dataTrim.find(row => row.startsWith(encryption("userCountry")))
      : "";
  let newArr = [];
  let newObj = {};
  if (x != undefined) {
    newArr = x.split("=").map(n => {
      return n;
    });
    let key = newArr[0];
    newObj[key] = newArr[1];
  }

  let Obj = {};
  let key = "",
    value = "";
  if (newObj != null) {
    // printLogs("newObj===",newObj)
    key = Object.keys(newObj)[0];
    value = Object.values(newObj)[0];
  }
 

    if (key != undefined && value != undefined) {

      Obj[decrypt(key)] = JSON.parse(decrypt(value));
    }
  

  return Obj;


}
function getCookieAuthorization(request) {
  let req = request.headers;

  let array = [];
  array.push(req);
  let dataTrim = array
    .map(i => (i["cookie"] ? i["cookie"].split(";") : []))[0]
    .map(i => i.trim());
  let x =
    dataTrim.length > 0
      ? dataTrim.find(row => row.startsWith(encryption("login")))
      : "";
  let newArr = [];
  let newObj = {};
  if (x != undefined) {
    newArr = x.split("=").map(n => {
      return n;
    });
    let key = newArr[0];
    newObj[key] = newArr[1];
  }

  let headersObj = {};
  let key = "",
    value = "";
  if (newObj != null) {
    key = Object.keys(newObj)[0];
    value = Object.values(newObj)[0];
  }
  headersObj["Content-Type"] = "application/json; charset=utf-8";
  headersObj["user-agent"] = request.headers["user-agent"]

  headersObj["Accept-Language"] =
    request.body &&
      request.body.headers &&
      request.body.headers["Accept-Language"]
      ? request.body.headers["Accept-Language"]
      : "ar";
  if (req.authorization) {
    headersObj["Authorization"] = req.authorization;
  } else {

    if (key != undefined && value != undefined) {
    

      headersObj[Object.keys(JSON.parse(decrypt(value)))[0]] = Object.values(JSON.parse(decrypt(value)))[0];
    }
  }

  return headersObj;
}
export { getRequestHeader, getCookieAuthorization,getCookieCountry }
