/** @type {import('next').NextConfig} */
// const BundleAnalyzerPlugin = require('webpack-bundle-analyzer').BundleAnalyzerPlugin;
const DuplicatePackageCheckerPlugin = require('duplicate-package-checker-webpack-plugin')
const path=require('path')
const { i18n } = require("./next-i18next.config");
const nextConfig = {
  // i18n: {
  //   locales: ['default', 'en', 'ar'],
  //   defaultLocale: 'default',
  //   localeDetection: false,
  // },

  webpack:(config,{isServer})=>{

      config.plugins.push(new DuplicatePackageCheckerPlugin())
      config.resolve.alias['fast-deep-equal'] = path.resolve(
        __dirname,
        'node_modules',
        'fast-deep-equal'
      )
  
    if(!isServer){
      config.resolve.fallback.dns=false
      config.resolve.fallback.net=false
      config.resolve.fallback.fs=false
      config.resolve.fallback.tls=false
      config.resolve.fallback.os=false
    }
    return config
  },
  transpilePackages: ["@mui/system", "@mui/material", "@mui/icons-material","swiper","localforage", '@mui/x-date-pickers'],
  modularizeImports: {
    // "@mui/material/?(((\\w*)?/?)*)": {
    //   transform: "@mui/material/{{ matches.[1] }}/{{member}}",
    // },
    "@mui/icons-material/?(((\\w*)?/?)*)": {
      transform: "@mui/icons-material/{{ matches.[1] }}/{{member}}",
    },
    '@mui/material/!(styles)/?*': {
      transform: '@mui/material/{{path}}/{{member}}',
      skipDefaultConversion: true,
    },

  
  },
  // useFileSystemPublicRoutes: false,
  // compress: true,
  // generateBuildId:async()=>{
  //   return '150'
  // },
  // output: 'standalone',
  // plugins:new DuplicatePackageCheckerPlugin() ,
  // webpack:(config,{buildId,dev,isServer,defaultLoaders,webpack})=>{
  //   config.plugin.push(new DuplicatePackageCheckerPlugin())
  //   return config
  // },
  // largePageDataBytes: 50 * 1000,
  onDemandEntries: {
    // period (in ms) where the server will keep pages in the buffer
    maxInactiveAge: 50 * 1000,
    // number of pages that should be kept simultaneously without being disposed
    pagesBufferLength: 5,
  },

  experimental :{
   
    largePageDataBytes:128*1000000,

    // dynamicImports:true,
    // css:true
  },
  i18n,
  // images: {

  //   domains:['192.168.1.136']
  //   // domains:['yottagate.com']
  //   // domains:['dashboard.yottagate.com']
  // },
  compress:true,
  reactStrictMode: true,
  // swcMinify: false,
  basePath: "",
  // basePath: '/yottapay',
  poweredByHeader:false,
//   api: {
//     bodyParser: false
// },
productionBrowserSourceMaps:true,

  env: {
    xmlSiteMapBasePath: 'https://hakeem.app',
    // apiBaseUrl: "http://192.168.1.136:8080",
    // mediaUrl: "http://192.168.1.136:8080/api/files/v1/files/download",
    // apiBaseUrl: "http://192.168.1.138:8080",
    // mediaUrl: "http://192.168.1.138:8080/api/files/v1/files/download",
  //  apiBaseUrl:'https://api.hakeem.app',
  //  mediaUrl:"https://api.hakeem.app/api/files/v1/files/download",
  apiBaseUrl: 'http://127.0.0.1:8080',
  mediaUrl :"https://api.hakeem.app/api/files/v1/files/download",
  verision:"1.0.24.9",
    // baseUrl:"http://192.168.1.136/web", //136
  baseUrl:"https://hakeem.app", //online
      // baseUrl:"http://192.168.1.136" //local
    notApprovedStatus: JSON.stringify({
      makerCheckerEntityStatus: [
        "closed",
        "marked-for-create-edit",
        "pending-approval-for-create",
        "pending-approval-for-unblock",
        "blocked",
        "locked"
      ],
      "maker-Checker-Entity-Status": [
        "closed",
        "marked-for-create-edit",
        "pending-approval-for-create",
        "pending-approval-for-unblock",
        "blocked",
        "locked"
      ],
      makerCheckerStatus: [
        "closed",
        "marked-for-create-edit",
        "pending-approval-for-create"
      ],
      "maker-checker-status": [
        "closed",
        "marked-for-create-edit",
        "pending-approval-for-create"
      ],
      makerOnlyStatus: ["closed"]
    }),
    approvedStatus: JSON.stringify({
      makerCheckerEntityStatus: [
        "approved",
        "marked-for-edit",
        "pending-approval-for-delete",
        "pending-approval-for-edit",
        "pending-approval-for-block"
      ],
      makerCheckerStatus: [
        "approved",
        "marked-for-edit",
        "pending-approval-for-delete",
        "pending-approval-for-edit"
      ],
      makerOnlyStatus: ["approved"],
      "maker-checker-status": [
        "approved",
        "marked-for-edit",
        "pending-approval-for-delete",
        "pending-approval-for-edit"
      ],
      "maker-only-status": ["approved"],

      "maker-checker-entity-status": [
        "approved",
        "marked-for-edit",
        "pending-approval-for-delete",
        "pending-approval-for-edit",
        "pending-approval-for-block"
      ]
    }),
    hakeemBookOnEntityTypesCodes : JSON.stringify([
    
      "private-clinic",
      "hospital-clinic",
      "private-clinic",
    
      "hospital",
      "hospital-department",
    
      "medical-center-clinic",
      "medical-center",
      "medical-center-department",
    
      "doctor",
    
      "diagnostic-centers",
    
      "medical-tourism",
      "optical-and-audiology",
    ]),
    
    hakeemShoppingOnEntityTypesCodes : JSON.stringify([
      "pharmacy",
    
      "supplier",
    
      "manufacturer",
    
      "honey-and-dates",
    
      "restaurant",
      
      "bakery-and-sweet",
    
      "juice-and-cafe",
    
      "foodstuff","merchant"
    ]),
    // siteName: {
    //   ar: "حكيم",
    //   en: "Hakeem"
    // },

    // HakeemMedicalCodes: [
    //   "hospital-clinic",
    //   "hospital",
    //   "private-clinic",
    //   "medical-center-clinic",
    //   "doctor",
    //   "hospital-department",
    //   "medical-center-department",
    

    // ],

    HakeemDietCodes: JSON.stringify([
      "restaurant",
      "juice-and-cafe",
      "bakery-and-sweet",
      "foodstuff"
    ])
    ,
    // notArchiveableEntityTypes:
    // [
    //   "customer",
    //   "employee"
    // ]
  // ,
  archiveableEntityTypes:
  JSON.stringify([
    "medical-center",
    "medical-tourism",
    "insurance-company",
   "honey-and-dates",
    "manufacturer",
   "diagnostic-centers",
    "supplier",
    "optical-and-audiology",
    "pharmacy",
    "doctor",
    "medical-center-department",
    "hospital-department",
    "hospital",
    "medical-center-clinic",
    "hospital-clinic",
    "private-clinic",
    "news",
    "medical-advices",
    "articles",
    "privacy-policy",
    "about",
    "product",
    "restaurant",
    "juice-and-cafe",
    "bakery-and-sweet",
    "foodstuff"
  ]),
  entitiesTypes: JSON.stringify([
    { "entityDetails": "maker-checker-entity-status" },
    { "addressDetails": "maker-checker-entity-status" },
    { "contactDetails": "maker-checker-entity-status" },
    { "identification": 'maker-checker-entity-status' },
    { "major": "maker-checker-entity-status" },
    { "certificatese": 'maker-checker-entity-status' },
    { "workDays": "maker-checker-entity-status" },
    { "entityImages": "maker-checker-entity-status" },
    { "workTimes": "maker-checker-entity-status" },
    { "machines": "maker-checker-entity-status" },
    { "entityDetails": "maker-checker-entity-status" },
    { "productUnitPrice": "maker-checker-status" },
    { "products": 'maker-checker-status' },
    {"entityMajors":'maker-checker-entity-status'},
    {"entityTitles":'maker-checker-entity-status'},
    {"entityWorkDays":'maker-checker-entity-status'}
  ]),
  // siteDescription: {
  //   ar:
  //     "احجز لدى افضل دكتور في اليمن. واطلب ادويتك بسهولة. وتسوق عبر سوق حكيم واطلب خدمة التوصيل الى باب البيت. واطلب فيزا علاجية",
  //   en:
  //     "The easiest, best healthcare app. It gives you the ability to book your medical appointment from your place without any effort.Hakeem also includes an elite of the best (doctors, consultants, specialists, hospitals, health centers, clinics, optical and hearing aids, pharmacies and everything related to the health fields) in Yemen"
  // },
  // notArchiveableEntityTypes: ["customer", "employee"],
  // childrenLabel: {
  //   ar: [
  //     { code: "hospital", value: "المستشفيات" },
  //     { code: "hospital-department", value: "الأقسام" },
  //     { code: "medical-center-clinic", value: "العيادات" },
  //     { code: "hospital-clinic", value: "العيادات" },
  //     { code: "optical-and-audiology", value: "البصريات والسمعيات" },
  //     { code: "pharmacy", value: "الصيدليات" },
  //     { code: "medical-center-department", value: "الأقسام" },
  //     { code: "medical-center", value: "المراكز" },
  //     { code: "insurance-company", value: "شركات التأمين" },
  //     { code: "doctor", value: "الأطباء" },
  //     { code: "private-clinic", value: "العيادات" }
  //   ],
  //   en: []
  // },
  languages: JSON.stringify([
    { id: 1, code: "en", name: "English", defaultLang: false },
    { id: 2, code: "ar", name: "العربية", defaultLang: true }
  ]),
  },
  eslint: {
    ignoreDuringBuilds: true
  },

  compiler: {
    styledComponents: true
  },
//  clearUrls:true,

async headers() {
  return [
    {
      "source":"/.well-known/apple-app-site-association",
    
      "headers":[{key:"Content-Type",value:"application/json"}]
    } ,
    {
      source: '/:api*', // becomes /docs/with-basePath
      headers: [
        {
          key: 'Access-Control-Allow-Origin',
          value: '*',
        },
        {
          key: 'Accept-Encoding',
          value: 'gzip,br,compress',
        },
      ],
    },  
    {
      source: '/term-conditions',
      headers: [
        {
          key: 'Cache-Control',
          value: 's-maxage=1, stale-while-revalidate=59',
        },
      ],
    },
   
  ]
}
};

module.exports = nextConfig;
