


import App from 'next/app'
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { theme, themeWithRtl } from "../theme";
import { LanguageProvider } from "../context/language/language.provider";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { appWithTranslation } from "next-i18next";
import {useState, useEffect} from 'react'
import {useRouter} from 'next/router'
import { CartProvider } from '../context/cart/use-cart';
import {encryption} from '../components/helpers/helpers'
import {v1} from 'uuid'
import Cookie from "js-cookie";
import TermsContext from "../context/Trems";
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/scrollbar"
import "swiper/css/navigation"
import "swiper/css/thumbs"
import '../styles/globals.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from '../components/components/Loader'
import * as config from '../next.config'
import {
  // QueryClient,
  QueryClientProvider,
  Hydrate,useQuery
} from '@tanstack/react-query'
import {ReactQueryDevtools} from  '@tanstack/react-query-devtools'
import '@fortawesome/fontawesome-svg-core/styles.css'
import {getvocabTermsObj} from '../pages/api/globals/terms/helpers'
import {queryClient} from '../react-query-config/queryClient'
import {dehydrate} from '@tanstack/react-query'
import {buidlObject} from '../components/helpers/helpers'
// import { getTermsByVocabCodes } from '../components/helpers/global/term/term';
function MyApp({ Component,pageProps,...props }) {
  const {locale}=useRouter()
  // const queryClient = new QueryClient()
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin]
  });
  const cacheLtr = createCache({
    key: "muiltr",
    // stylisPlugins: [rtlPlugin]
  });
 
  // console.log("info============>",info)
  let appTerms=pageProps.dehydratedState.queries.filter(ele=>ele.queryKey.includes("app-terms"))
  let statusTerms=appTerms[0].state.data.filter(i=>i.vocCode== "maker-only-status" || i.vocCode==  "maker-checker-entity-status" || i.vocCode== "maker-checker-status")
  let StatusIds =pageProps.dehydratedState.queries.filter(ele=>ele.queryKey.includes("statusIds")).length>0?pageProps.dehydratedState.queries.filter(ele=>ele.queryKey.includes("statusIds"))[0].state.data:[]
  useEffect(()=>{
    if(!Cookie.get(encryption("uuid"))){
      Cookie.set(encryption("uuid"),encryption(v1()))
    }
   
    if(appTerms[0].state.data.filter(i=>StatusIds.includes(i.statusId)&& i.vocCode=="countries").filter(i=>i.language.code==locale)&& appTerms[0].state.data.filter(i=>StatusIds.includes(i.statusId)&&i.vocCode=="countries").filter(ele=>ele.language.code==locale&&ele.field2&&buidlObject(ele.field2).service).length>0){
      let country= appTerms[0].state.data.filter(i=>StatusIds.includes(i.statusId)&&i.vocCode=="countries").filter(ele=>ele.language.code==locale&&ele.field2&&buidlObject(ele.field2).service)[0].code
     
      Cookie.set(encryption("userCountry"),encryption(JSON.stringify(country)))
      }
  },[props])

  useEffect(()=>{
    
    if(locale=="ar"){
      setDirection("rtl")
    }
    else{
      setDirection("ltr") 
    }
  
  },[locale])
 
  
  
  const [direction, setDirection] = useState("rtl");
  return ( 
    <QueryClientProvider client={queryClient}>   
    {/* <Hydrate state={pageProps.dehydratedState}> */}
    <ReactQueryDevtools initialIsOpen={false} />
    <CacheProvider value={direction == "rtl" ?cacheRtl:cacheLtr}>
  <ThemeProvider theme={direction == "rtl" ? themeWithRtl : theme}>
  <CartProvider>
  <CssBaseline />
    <LanguageProvider>
    <TermsContext.Provider 
    value={{
            countries:appTerms[0].state.data.filter(i=>i.vocCode=="countries") ,
            hakeemServices:appTerms[0].state.data.filter(i=>i.vocCode=="hakeem-services"),
            entityTypes:appTerms[0].state.data.filter(i=>i.vocCode=="entity-types"),
            statusIds:StatusIds,
            productCategories:appTerms[0].state.data.filter(i=>i.vocCode=="product-categories"),
            countriesService:appTerms[0].state.data.filter(i=>i.vocCode=="countries").filter(i=>i.language.code==locale&&i.field2&& JSON.parse(i.field2)!=null &&JSON.parse(i.field2).service=="true"),
            timeZones:appTerms[0].state.data.filter(i=>i.vocCode=="time-zone"),
            status:statusTerms,
            queries:pageProps.dehydratedState.queries
      }}> 
   
      <Loader/>
      <Component {...pageProps} />
      </TermsContext.Provider>
    </LanguageProvider>
    </CartProvider>
  </ThemeProvider>
</CacheProvider> 
{/* </Hydrate> */}
</QueryClientProvider>
  )
}
MyApp.getInitialProps=async(appContext) =>{
  let pageProps={}
  if(App.getInitialProps){
    pageProps=await App.getInitialProps(appContext)
  }
await queryClient.prefetchQuery({queryKey:["app-terms"],queryFn:async()=>await getvocabTermsObj({"vocabCode": [
  "maker-checker-status",
  "maker-checker-entity-status",
  "maker-only-status",
  "countries",
  "hakeem-services",
  "entity-types",
  "slug-entity-classes",
  "blog-types",
  // "days",
  // "view-types",
  // "statistic-entities",
  // "product-categories",
  // "entity-categories",
  // "cities",
  // "districts",
  // "majors-types",
  // "sub-majors-types"
]},appContext.ctx.req),staleTime:60*10*1000})
  let appTermsQueryClientHydrate=dehydrate(queryClient)

  // console.log("appTermsQueryClientHydrate==========>",appTermsQueryClientHydrate)
  let configStatus=JSON.parse(config.env.approvedStatus)['maker-checker-entity-status'].concat( JSON.parse(config.env.approvedStatus)['maker-checker-status']).concat( JSON.parse(config.env.approvedStatus)['maker-only-status'])
  let statusTerms=appTermsQueryClientHydrate&&appTermsQueryClientHydrate.queries.length>0&&appTermsQueryClientHydrate.queries[0].state&&appTermsQueryClientHydrate.queries[0].state.data.filter(i=>i.vocCode== "maker-only-status" || i.vocCode==  "maker-checker-entity-status" || i.vocCode== "maker-checker-status")
  let StatusIds =statusTerms.filter(ele=>configStatus.includes(ele.code)).map(ele=>ele.id)
  await queryClient.prefetchQuery({queryKey:["statusIds"],queryFn: ()=> StatusIds,staleTime:60*10*1000});
  return {
    pageProps: {...pageProps,
      dehydratedState:dehydrate(queryClient),
    
   
    }
  };
}
export default appWithTranslation(MyApp);