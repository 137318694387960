import { colors } from '@mui/material';

export default {
  styleOverrides: { 
     contained: {
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14)',
    color:"#ffffff",
    borderRadius:"16px",
    // width:"100%",
    // backgroundColor: "#0c1444",
    // '&:hover': {
    //   backgroundColor: "#0c1444"
    // }
  }
}
};
