export const constants = {
  //_________________links___________________
  productUrl: "products",
  productPageUrl: "hakeem",
  mainProductPageUrl: "products/hakeem/",
  registrationUrl: "user-ms/user/register",
  searchSpecialitaiesUrl: "specialitaies",
  myReservationsLink: "admin/reservation/myReservations",
  myReservationsForClinicLink: "admin/reservation/viewReservationForClinic",
  entityLink: "profile",
  productLink: "products",
  entityInsuranceCompaniesMandatoryObjectName: "entityInsuranceCompanies",
  about: "/about",
  ContactUs: "ContactUs",
  HakeemMedical: "/profile",
  HakeemDiet: "/profile/",
  HakeemProducts: "/products",
  Blog: "blog/[pid]",
  detailsOrder: "hakeem-diet/[...diet].js",
  TrademarksLink: "/trade-marks",
  offersLink: "/offers",
  sponsorsLink: "/sponsors",
  favoritesLink: "/favorites",
  termandconditionsLink: "/term-conditions",
  orders: "orders/",
  signUpLink: "/user-ms/sign-up",
  loginLink: "/user-ms/login",
  orders: "orders/",
  changePassworLink: "/user-ms/change-password",
  forgetPasswordLink: "/user-ms/forget-password",
  myReservationsLink:"/myReservations",
  // ________ constants limits____________
  memoryCacheExpire: 24 * 60 * 60 * 1000,
  limitOfEntityImages: 500,
  limitOfWorkingTime: 1000,
  test: false,
  storedData: "storedData",
  productDetails: "product-details-types",
  MobileMed: 778876881,
  MobileDite: 778876004,
  //_________________MedicalMarket___________________
  showMedicalShopping: true,
  //  showMedicalTrademarks:true,
  showMedicalProducts: true,
  showMedicalOffers: false,
  showMedicalBasket: false,
  //_________________MedicalMarket___________________
  showFoodShopping: true,
  showFoodOffers: true,
  showFoodBasket: false,
  // getTermsByVocabCode
  ENTITY_TYPES_VOCAB_CODE: "entity-types",
  BLOG_TYPES_VOCAB_CODE: "blog-types",
  ENTITY_IMAGE_TYPES_VOCAB_CODE: "entity-image-types",
  SLUG_ENTITY_CLASSES_VOCAB_CODE: "slug-entity-classes",
  PAYMENT_TYPES_VOCAB_CODE: "payment-types",
  TRANSACTION_PAYMENT_TYPES_VOCAB_CODE: "transaction-payment-types",
  productCategories: "product-categories",
  units: "units",
  unitTypes: "unit-types",
  // axios urls
  // globals
  BLOGS_URL: "api/cms/blog/get",
  BLOG_FILES_URL: "api/cms/blog/list_api",
  // accounts
  LIST_BALANCE_URL: "api/accounts/getLastBalance",
  // products
  PRODUCT_UNIT_PRICE_URL: "api/products/productUnitPrice/get",
  PRODUCT_URL: "api/products/product/getProduct",
  OFFERS_URL: "api/products/offers/get",
  OFFERS_CATES_URL: "api/products/offers/getOffers",
  TRADEMARKS_URL: "api/products/tradeMark/get",
  // products details
  PRODUCT_DETAILS_URL: "api/ProductDetails/get",
  // registeration
  ENTITY_DETAILS_URL: "api/registeration/entityDetails/get",
  ENTITY_IMAGES_URL: "api/registeration/entityImages/get",
  ENTITY_MAJORS_URL: "api/registeration/entityMajors/get",
  ENTITY_WORK_TIME_URL: "api/registeration/workTime/get",
  ENTITY_CERTIFICATES_URL: "api/registeration/entityCertificates/get",
  ENTITY_MACHINES_URL: "api/registeration/entityMachines/get",
  ENTITY_INSURRANCE_URL: "api/registeration/entityInsurrance/get",
  ENTITY_DOCTORS_URL: "api/registeration/entityDoctors/get",
  ENTITY_WITH_COUNTRIES_URL: "api/registeration/entityDetailsWithCountries/get",
  OCCUPATION_DETAILS_URL: "api/registeration/occupationDetails/get",
  REPORT_PRODUCT_OFFERS_CATES_URL: "api/products/offers/getReportProductOffers",
  CONFIG_MANDATORY_URL: "api/registeration/configMandatory/get",
  LANGUAGE_API_FILE_URL: "api/globals/languages/get",
  SLUG_DETAILS_URL: "api/cms/slug/getSlug",
  RESERVATION_URL: "api/reservation/Booking",
  Days_VOCAB_CODE: "days",
  // href for link
  ENTITY_DETAILS_LINK: "detailsmedical/",
  offersDietLink: "/offers/diet",
  offersMedicalLink: "/offers/medical",
  basket: "/basket/[pid]",
  AddAddress: "/add-address",
  view_addresses: "/addresses-delivery",
  getByDateOfReservationFrom_File_URL:
    "api/reservation/getByDataOfReservationFrom",
  order_medicine: "/order",
  product_Offers_hakeem_medical: "api/products/productOffers/get",
  blogsLink: "/blogs",
  favoriteBlogs: "blogs",
  faqLink: "/faq",
  articalsLink: "/articles",
  newsLink: "/news",
  slugEntityClassesVocabCode: "slug-entity-classes",
  entityDetailsSlugEntityClassCode: "entity-details",
  blogSlugEntityClassCode: "blog",
  productSlugEntityClassCode: "product",
  StoreKeyForSubTypeCartOfMedicalProduct: "products",
  StoreKeyForTypeCartOfMedical: "medical",
  StoreKeyForTypeCartOfFood: "diet",
  StoreKeyForSubTypeCartOfFoodProduct: "meals",
  sponsorUrl: "230244",
  freeUrl: "2302#44",
  contactuslink: "/contact-us",
  showMedicalTrademarks: true,
  dashboardUrl: "/dashboard1",
  dontShowPaymentDialogInReservation: "reservation-payment-dialog",
  dontShowPaymentDialogInOrder: "order-payment-dialog",
  showWrkingTimeForEntity: false,
  showGender: false,
  showAbout: true,
  showLatestBlogs: true,
  showContactUs: false,
  showSearchByMajor: true,
  showBlogComments: false,
  showlogin: true,
  showSearchByDistrict: false,
  showSearchByInsurance: false,
  showSearchByInsuranceInSearchForm: false,
  showSearchByHospital: false,
  showSearchByCenter: false,
  showSearchByDcotors: true,
  showContactUs: true,
  showContactUsForm: false,
  showMoreEntityProfile: true,
  showInsurranceFilter: false,
  showMoreButtonFaq: true,
  showWorkingTimeForEntity: true,
  showMyProfileData: true,
  showMyAppointment: true,
  showCreateAccountButton: true,
  showRatingSection: true,
  showDashboardLink: true,
  showShopNowLink: true,
  showProductData: false,
  aboutBlogTypeTermCode: "about",
  privacyPolicyBlogTypeTermCode: "privacy-policy",
  articlesBlogTypeTermCode: "articles",
  medicalAdvicesBlogTypeTermCode: '"medical-advices',
  blogSlugEntityClassCode: "blog",
  entityDetailsSlugEntityClassCode: "entity-details",
  productSlugEntityClassCode: "product",
  makerCheckerStatusVocabCode: "maker-checker-status",
  makerCheckerEntityStatusVocabCode: "maker-checker-entity-status",
  makerOnlyStatusVocabCode: "maker-only-status",
  blogTypesVocabCode: "blog-types",
  majorsTypesVocabCode: "majors-types",
  slugEntityClassesVocabCode: "slug-entity-classes",
  titlesTypesVocabCode: "titles",
  entityImageTypesVocabCode: "entity-image-types",
  idTypesVocabCode: "id-types",
  universitiesVocabCode: "universities",
  certificateMajorsVocabCode: "certificate-majors",
  certificateTypesVocabCode: "certificate-types",
  incomeRateVocabCode: "income-rate",
  companiesVocabCode: "companies",
  occupationTypesVocCode: "occupation-types",
  successMessageClass: "success",
  errorMessageClass: "error",
  warningMessageClass: "warning",
  authorizationKeyName: "Authorization",
  afterLoginUrl: "/",
  showEntityChildren: true,
  entityTypesVocabCode: "entity-types",
  subMajorsVocCode: "sub-majors-types",
  paymentTypesVocCode: "payment-types",
  ratingMandatoryObjectName: "rating",
  entityMachinesMandatoryObjectName: "entityMachines",
  reservationMandatoryObjectName: "reservation",
  contatctTypesVocCode: "contact-types",
  showReservationForClinic: true,
  showSearchSpecialitaies: true,
  showCustomerChart: true,
  showTotalElementsOfSearchResult: false,
  cities: "cities",
  countries: "countries",
  districts: "districts",
  unitTypes: "unit-types",
  gendersVocabCode: "genders",
  productUnitPriceTypes: "product-unit-price-types",
  entityDetailsExpireTime: 1*30 * 60 * 60 * 1000,
  entityWorkTimesExpireTime: 1*30 * 60 * 60 * 1000,
  entityAboutExpireTime:1*30 * 60 * 60 * 1000,
  entityCertificatesExpireTime:1*30 * 60 * 60 * 1000,
  entitySlugExpireTime:1*30 * 60 * 60 * 1000,
  entityAddressDetailsExpireTime: 1*30 * 60 * 60 * 1000,
  entityMajorsExpireTime: 1*30 * 60 * 60 * 1000,
  entityLogoExpireTime:1*30 * 60 * 60 * 1000,
  entityParentConfigExpireTime:1*30 * 60 * 60 * 1000,
  entityMandatoryConfigExpireTime: 1*30 * 60 * 60 * 1000,
  vocabTermsExpireTime: 1*30 * 60 * 60 * 1000,
  globalLanguageExpireTime:1*30 * 60 * 60 * 1000,
  entityKeyWordsExpireTime:1*30 * 60 * 60 * 1000,
  entityDetailsCacheKey:"registration_web_entity_details_",
  entityMajorsCacheKey:"registration_web_entity_majors_",
  entityAddressDetailsCachekey:'registration_web_entity_addressDetails_',
  entitySlugCacheKey:'registration_web_entity_slug_',
  entityAboutCacheKey:"registration_web_entity_about_",
  entityLogoCacheKey:'registration_web_entity_logo_',
  entityMandatoryConfigCacheKey:'registration_web_entity_mandatory_config' ,
  entityParentConfigCacheKey:'registration_web_entity_parent_config',
  entityCertificatesCachekey:"registration_web_entity_certificate_",
  entityWorkTimesCacheKey:'registration_web_entity_workTimes_',
  profileCacheKey:"registration_web_profile_",
  globalLanguage:"global_web_language",
  entityKeyWordsCachekey:"registration_web_entity_keywords_",
  productSlugCacheKey:'products_web_product_slug_',
  productCacheKey:'products_web_product_',
  productDetailsCacheKey:'products_web_productDetails_',
  unitTypes: "unit-types",
  capacityTypes: "capacity-types",
  sizeTypes: "size-types",
  capacity: "capacity",
  size: "size",
  offersMedicalLink: "/offers",
  ENTITY_ADDRESS_URL:"api/registeration/addressDetails/get",
  HakeemTredmarks: "/tredmarks",
  tredmarkUrl: "trade-marks",
  PurchaseOrder: "/purchase-order",
  imagesDirNameInPbublic:"/upload/",
  deleteAccountUrl: "/user-ms/delete-account"
};
