import { constants } from "../../constants";
import aesjs from "aes-js";
import Cookie from 'js-cookie'
import * as config from "../../next.config.js";

function setFiltersCookie(key, value) {
    try {
        document.cookie = `${key}=${value} ;path=${config.basePath?config.basePath:"/"}`;
    } catch (error) {
        console.error(error);
    }

}

// function to delete cookie by name or all cookies
function deleteCookie(name, isAll) {
    try {
        if (isAll === true) {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++)
                deleteCookie(cookies[i].split("=")[0]);
        } else {
            document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=${config.basePath!=""?config.basePath:"/"}`;
        }
    } catch (error) {
        console.error(error);
    }
}

// function to get cookie value by name
function getCookieValue(name) {
  
    // console.log("data=======>","خلودߘ뢬".replace(/[^\w\u0621-\u064A\s]/gi, ' '))
    if(Cookie.get(encryption(name))){
     
     let data=decrypt(Cookie.get(encryption(name)))
   
     if(typeof data =='string'){
// console.log("data======>",data)
        // console.log("data========>",JSON.parse({"Authorization":"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOjMwMDUsInByZWZlcnJlZF91c2VybmFtZSI6Ijc3NDI1MTE1NiIsImV4cCI6MTczNzM1Mzk5NiwiaWF0IjoxNzA1ODE3OTk2NzMyfQ.dh4fPVFT1rJ5Jb0POjEX5y1_mn0JtARTfp6Nn80FG1YY6BgFssq2GVeUBoJQgPvc7xNHwDNiaxtM6Gu394Hh3g","userID":3005,"userName":"774251156","entityDetailsId":3055,"userTypeId":25,"officialName":"خلودߘ뢬",image":{"fileId":6596,"fileEntityClassId":233,"filePath":'https://api.hakeem.app/api/files/v1/files/download/2022/3/15/entity-images-3005-144295-1647346786668.jpg',"fileSize":58547,"fileAlt":null}}))
        // return JSON.parse(data.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, ''));
        return JSON.parse(data)
     }
     else{
        return data;  
     }
       
    }
    else{
        return false
    }
}


// this function will return all cookies {name: value}
function getAllCookies() {
    try {
        let allCookies = {};
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookieName = cookies[i].split("=")[0].trim();
            if (cookieName !== 'csrftoken') {
                allCookies[cookieName] = getCookieValue(cookieName);
            }
        }
        return allCookies;
    } catch (error) {
        console.error(error);
    }
}

function getWeekDates(startDate, daysToAdd) {
    let aryDates = [];

    for (let i = 0; i <= daysToAdd; i++) {
        let currentDate = new Date();

        currentDate.setDate(startDate.getDate() + i);
        aryDates.push({
            "dayJson": DayAsString(currentDate.getDay()),
            "currentDate": currentDate.getDate(),
            "MonthAsString": MonthAsString(currentDate.getMonth()),
            "year": currentDate.getFullYear(),
            "timestamp": currentDate.getTime()
        });
    }

    return aryDates;
}

function MonthAsString(monthIndex) {
    var d = new Date();
    var month = new Array();
    month[0] = "1";
    month[1] = "2";
    month[2] = "3";
    month[3] = "4";
    month[4] = "5";
    month[5] = "6";
    month[6] = "7";
    month[7] = "8";
    month[8] = "9";
    month[9] = "10";
    month[10] = "11";
    month[11] = "12";

    return month[monthIndex];
}
function DayAsString(dayIndex) {
    var weekdays = new Array(7);
    weekdays[0] = { name: "الأحد", code: "sunday" };
    weekdays[1] = { name: "الأثنين", code: "monday" };
    weekdays[2] = { name: "الثلاثاء", code: "tuesday" };
    weekdays[3] = { name: "الاربعاء", code: "wednesday" };
    weekdays[4] = { name: "الخميس", code: "thursday" };
    weekdays[5] = { name: "الجمعة", code: "friday" };
    weekdays[6] = { name: "السبت", code: "saturday" };

    return weekdays[dayIndex];
}

const isFloat = (n) => {
    return n === +n && n !== (n | 0);
};

function getNextTime(from, to, freq,freqTimeBiggerThanDifrence) {
    const allTimes = [];
    let nextTime = from + freq;
    // check if to bigger than from

    if ((parseInt(freq) !== 0) && (from < to)) {
        for (let i = from; i <= to; i += freq) {
            let fromTime = i / 60;
            let fromTimeOnly = fromTime.toString().split(".")[0];
            let fromMins = "00";
            if (isFloat(fromTime)) {

                let mins = "0." + fromTime.toString().split(".")[1];
                mins = parseFloat(mins);
                mins = Math.round(mins * 60);
                
                allTimes.push([parseInt(fromTimeOnly), mins]);
            } else {
                allTimes.push([parseInt(fromTimeOnly), "00"]);
            }

        }
        return allTimes
    }else{
 
        if((to -from)<freq){
           
              let fromTime = from / 60;
             
            let fromTimeOnly = fromTime.toString().split(".")[0];
          
            let fromMins = "00";
            if (isFloat(fromTime)) {
             
                let mins = "0." + fromTime.toString().split(".")[1];
                mins = parseFloat(mins);
                mins = Math.round(mins * 60);
                
                allTimes.push([parseInt(fromTimeOnly), mins]);
            } else {
                allTimes.push([parseInt(fromTimeOnly), "00"]);
            }
        } 
   
        return allTimes
    }
  
}
function getTimeZone(){
    let timeZone=new Date().getTimezoneOffset(),offset=Math.abs(timeZone)
  
   return (timeZone<0?"+":"-")+("00"+Math.floor(offset/60)).slice(-2)+":"+("00"+(offset%60)).slice(-2)
}
function nFormatter(num,digits){
    const lookup=[
        {value:"1",symbol:""},
    
        {value:"1e3",symbol:"k"},
        {value:"1e6",symbol:"M"},
        {value:"1e9",symbol:"G"},
        {value:"1e12",symbol:"T"},
        {value:"1e15",symbol:"P"},
        {value:"1e18",symbol:"E"},
    ];
    const rx=/\.0+$|(\.[0-9]*[1-9])0+$/
    var item =lookup.slice().reverse().find(item=>num>=item.value)
    return item?(num/item.value).toFixed(digits).replace(rx,"$1")+item.symbol:"0"
}
function getLogs(variable){
    //  console.log(variable)
}
export const printLogs=(string ,variable)=>{

    if((config&&config.env.apiBaseUrl.indexOf("hakeem.app")<0)&&(config&&config.env.apiBaseUrl.indexOf("127.0.0.1")<0)){
          return  console.log(string,variable) 
    }

}


export const decrypt = text => {
    const key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    const iv = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];
    var encryptedBytes1 = aesjs.utils.hex.toBytes(text);
    var aesCtr2 = new aesjs.ModeOfOperation.ctr(key, iv);
    var decryptedBytes = aesCtr2.decrypt(encryptedBytes1);
    var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    return decryptedText;
  };
  export const encryption = text => {
    const key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    const iv = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];
    var textBytes = aesjs.utils.utf8.toBytes(text);
    var aesCtr = new aesjs.ModeOfOperation.ctr(key, iv);
    var encryptedBytes = aesCtr.encrypt(textBytes);
    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
  
    return encryptedHex;
  };
  var newObj = {};
  export const encryptLocalStorage = (newKey, newValue) => {
    newObj[newKey] = newValue;
  
    return window.localStorage.setItem(
      encryption(constants.storedData),
      encryption(JSON.stringify(Object.assign({ [newKey]: newValue }, newObj)))
    );
  };
  export const decryptLocalStorage = () => {
    if (window.localStorage.getItem(encryption(constants.storedData))) {
      newObj = JSON.parse(
        decrypt(window.localStorage.getItem(encryption(constants.storedData)))
      );
      return newObj;
    } else {
      return null;
    }
  };
  function DayAsString2(dayIndex) {
    var weekdays = new Array(7);
    weekdays[0] = { name: "السبت", code: "saturday" };
    weekdays[1] = { name: "الأحد", code: "sunday" };
    weekdays[2] = { name: "الأثنين", code: "monday" };
    weekdays[3] = { name: "الثلاثاء", code: "tuesday" };
    weekdays[4] = { name: "الاربعاء", code: "wednesday" };
    weekdays[5] = { name: "الخميس", code: "thursday" };
    weekdays[6] = { name: "الجمعة", code: "friday" };
if(dayIndex){
    return weekdays[dayIndex];
}
else
    return weekdays;
}
function stripeHTMLTags(text){
    return text.replaceAll(/<\/?[^>]+(>|$)/gi,"")
}
function stripeLnLrWithBr(text){
    return text.replaceAll(/(\r\n|\r|\n)/g, "<br/>");
}
function isOnlyDigits(string){
 for(let i=0;i<string.length;i++){
var asci=string.charCodeAt(i)
if(asci<48|| asci>57){

return false

}

} 

return true
}

function buidlObject(obj){
    if(obj){
    return Object.fromEntries(obj.replaceAll('"','').replaceAll("{","").replaceAll('"','').replaceAll("}","").split(',').map(i=>i.split(':'))
    .map(ele=>
         ele.map(x=>{
            return x.trim()
    })))
}

}
export const setCookiesValues=async(key,value)=>{
     Cookie.set(encryption(key), encryption(value), { path: config.basePath ? config.basePath : "/" });
     return true
  }
export {
    buidlObject,
    isOnlyDigits,
    stripeLnLrWithBr,
    DayAsString2,
    setFiltersCookie,
    deleteCookie,
    getCookieValue,
    getAllCookies,
    getWeekDates,
    getNextTime,
    getTimeZone,
    nFormatter,
    getLogs,
    // printLogs,
    DayAsString,
    stripeHTMLTags
   
}