import palette from '../palette';

export default {
  styleOverrides: { 

  input: {
    '&::placeholder': {
      opacity: 1,
      color: palette.text.secondary
    },
    // padding:"16.5px 5px !important"
  },
  // fieldset:{borderRadius:"12px"},
  // borderRadius:"12px"
}
};
