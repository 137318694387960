import React from 'react';

import { InjectRTL } from '../../public/assets/styles/global.style';
import Cookie from 'js-cookie';
import { isRTL, isLocale } from './language.utils';
import StyleProvider from "../../components/StyleProvider";
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next'
import axios from 'axios'
import * as config from '../../next.config.js'
import {getCookieValue} from '../../components/helpers/helpers'
const LanguageContext = React.createContext({} as any);
  const LanguageProvider = ({ children, messages }) => {

  const router=useRouter()
  const changeLanguage = (newLocale): void => {
    setLocale(newLocale);
    document.documentElement.lang = newLocale;
    Cookie.set('locale', newLocale);
    // i18n.changeLanguage(newLocale)
    // router.push({ pathname: router.pathname, query: router.query }, router.asPath, { locale: newLocale })
  };
  const {i18n}=useTranslation()
  const [locale, setLocale] = React.useState('ar');
  React.useEffect(() => {

    if(Cookie.get("locale")==undefined){

      Cookie.set('locale', i18n.language);
    
      document.documentElement.lang =i18n.language
    //  router.replace({ pathname: router.pathname, query: router.query }, router.asPath, { locale: i18n.language })
    }
   
    const localSetting = Cookie.get('locale');
    if(localSetting){

 
    if ((localSetting==router.locale&& isLocale(localSetting))) {
      // console.log("r==========>",localSetting,router.locale)
      Cookie.set('locale', localSetting);
      document.documentElement.lang = localSetting;
  
      setLocale(localSetting);
      router.replace({ pathname: router.pathname, query: router.query }, router.asPath, { locale: localSetting })
      // console.log("if=========>")
    }
    else{
      Cookie.set('locale', router.locale );
      document.documentElement.lang = router.locale ;
  
      setLocale(router.locale );
      if(router.pathname!="clear-cache"){
        router.replace({ pathname: router.pathname, query: router.query }, router.asPath, { locale: router.locale })
      }
      
      // if(router)
    }
    // else{
    //   document.documentElement.lang = router.locale;
    //   Cookie.set('locale',router.locale);
    //   setLocale(router.locale);
    //   // console.log("else=========>",router,router.locale)
    //   // router.replace({ pathname: router.pathname, query: router.query }, router.asPath, { locale: router.locale})
    // }
  }
    if (getCookieValue("login")) {
     
      var configLangugae = {
        method: 'get',
        url: config.basePath + '/api/globals/languages/get',
        headers: {
            'Content-Type': 'application/json'
        },
        params: {}
    }
      axios(configLangugae)
      .then((resp) => {
      // console.log("resp=========>",resp)
        let data = {
          langId: resp.data.data.filter(ele=>ele.code==locale)[0].id
        }
    
        axios.post(`${config['basePath']}/api/user/patchUser`, data)
          .then((resp) => {
            
            if (resp.data.data != null) {
            }
          }).catch(err => console.log(err))
      
      }).catch(err => console.log(err))
    
      }
  },[]);
  let isRtl = isRTL(locale);

  return (
    <LanguageContext.Provider value={{ locale, changeLanguage, isRtl }}>
      {/* {console.log("locale=========>",locale)} */}
        <InjectRTL lang={locale} dir={isRtl ? 'rtl' : 'ltr'}style={{height:"100%"}}>
        <StyleProvider direction={isRtl ? 'rtl' : 'ltr'} >
            {children}
            </StyleProvider>
        </InjectRTL>
    </LanguageContext.Provider>
  );
};

 const useLocale = () => React.useContext(LanguageContext);
export {useLocale,LanguageProvider}